import React, { Component } from "react"
import Modal from "react-modal"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkGfm from "remark-gfm"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopContainer from "../components/TopContainer"
import img from "../images/analytics2.jpg"
import { Section, Group, Col, CtaButton } from "../components/common"

import iconCheck from "../images/icons/check.svg"

import covidSocial from "../images/reports/covidSocial.jpg"
import mspiVsMarket from "../images/reports/mspiVsMarket.jpg"
import mspiVsEsg from "../images/reports/mspiVsEsg.jpg"
import dapl from "../images/reports/dapl.jpg"
import pingAn from "../images/reports/pingAn.jpg"
import covid from "../images/reports/covid.jpg"
import sri2012 from "../images/reports/sri2012.jpg"
import sri2013 from "../images/reports/sri2013.jpg"
import findingTheS from "../images/reports/findingTheS.jpg"
import aiNlp from "../images/reports/aiNlp.jpg"
import blendingAlt from "../images/reports/socialData.jpg"
import socialData from "../images/reports/blendingAlt.jpg"
import materialAlpha from "../images/reports/materialAlpha.jpg"
import theoryOfChange from "../images/reports/theoryOfChange.jpg"

class AnalyticsPage extends Component {
  state = {
    modalIsOpen: false,
    activeReport: null,
    name: "",
    email: "",
    company: "",
    position: "",
    errors: [],
    reports: [
      {
        title:
          "Inside S-Factor: A Source of Alpha and a Greater Understanding of Materiality",
        description: `February 2022 In an industry first, The Social Factor Data Company, (“S-Factor”) has proven the link between material social performance (the “S” in ESG) and predicting financial outcomes for investment purposes. The release of their paper , “Inside S-Factor: A Source of Alpha and a Greater Understanding of Materiality”, contains four case studies that test investment strategies designed by The S Factor Co. and validated externally by two quantitative firms.`,
        img: materialAlpha,
        id: "materialAlpha",
      },
      {
        title: `Eagle Alpha Spotlight: ESG - Social Data`,
        description: `On August, 23, 2021 Eagle Alpha produced a spotlight whitepaper titled, “ESG – Social Data” in which The S-Factor Co. is featured. With the introduction of new sustainable finance disclosure regulations and taxonomies from the UN and across the EU, North America, and Asia, it is more important now more than ever to understand ESG data and where investors can source reliable ESG data.<br><br>*About Eagle Alpha*<br>Established in 2012, Eagle Alpha is the pioneer connecting the universe of alternative data. Their solutions are used by buyside firms, private equity firms, corporates, central banks and government to make data-driven investment and business decisions.`,
        img: socialData,
        id: "socialData",
      },
      {
        title: `Blending Alternative Data for ESG Investing`,
        description: `On August 17, 2021, Eagle Alpha held a virtual ESG Hackathon in New York. The S Factor Co. was a part of the winning team. Here is a copy of the results in Eagle Alphas whitepaper: "Blending Alternative Data for ESG Investing. The S-Factor is featured for their contributions to a blend of alternative data sets for ESG and the signals and raw data content leading to those signals in tandem with other providers. <br><br>*About Eagle Alpha*<br>Established in 2012, Eagle Alpha is the pioneer connecting the universe of alternative data. Their solutions are used by buyside firms, private equity firms, corporates, central banks and government to make data-driven investment and business decisions.`,
        img: blendingAlt,
        id: "blendingAlt",
      },

      {
        title: `Eagle Alpha Spotlight: NLP & AI for ESG Analysis`,
        description: `On September 7, 2021 Eagle Alpha produced a spotlight whitepaper called, “NLP & AI For ESG Analysis” in which the The S-Factor Co. is featured. In the ESG landscape, some of the pain points for asset managers, are a lack of comparable, high-quality, high-frequency raw data, a lack of standardized definitions of sustainable activities, conflicting ESG taxonomies, and divergence in scoring, and methodologies across traditional ESG data vendors. The S-Factor proves to solves some of the industry’s biggest issues.<br><br>*About Eagle Alpha*<br>Established in 2012, Eagle Alpha is the pioneer connecting the universe of alternative data. Their solutions are used by buyside firms, private equity firms, corporates, central banks and government to make data-driven investment and business decisions.`,
        img: aiNlp,
        id: "aiNlp",
      },

      {
        title: `Finding the 'S' in ESG`,
        description: `On June 29, 2021 Alternatives Watch produced a special report called, "Finding the S in ESG" in which The S-Factor Co. is featured. The report, which sought the opinions of a broad range of investors to get an idea of how far the discussion of social justice has come in the world of institutional investing.<br> <br>*About Alternatives Watch*<br> Alternatives Watch is a leading online news service dedicated to institutional investment in alternative investments including hedge funds, private equity, private credit, real estate, infrastructure, and CTAs/managed futures.`,
        img: findingTheS,
        id: "findingTheS",
      },

      {
        title: `COVID-19 Social Data`,
        description: `The S-Factor Co. has been tracking companies' behaviours and change in behaviours regarding their ethics, supply chain, employees and communities since early Feb 2020 in real-time. This report captures 45 companies, across region and industry over 35 days as an example. `,
        img: null,
        id: "covidSocial",
        img: covid,
      },
      {
        title: `MSPI vs Market`,
        description: `The MSPI is S-Factor's Social Performance Index. It measures companies' social impact in relation to their compliance, best-practice norms, public sentiment, externalities and financial performance. Download the 2019 MSPI snapshot here.`,
        id: "mspiVsMarket",
        img: mspiVsMarket,
      },
      {
        title: `MSPI vs ESG Indices`,
        description: `The difference between the MSPI (which is S-Factor’s Social Performance Index) and other ESG Indices on the market is the addition of 1,000s of social factor metrics and the proprietary algorithms which measure companies social policy compliance, best-practice behaviour and management, public sentiment, externalities, and their financial performance.`,
        id: "mspiVsEsg",
        img: mspiVsEsg,
      },
      // {
      //   title: `DAPL Case`,
      //   description: `The case for the Dakota Access Pipeline (DAPL) is and will be, a notorious social-factor business case for years to come. The catastrophic cost of discounting the S-Factors in an ESG/Impact study cost substantial loss to the communities, stakeholders, shareholders, and its tertiary effects resulted in further loss of business not only within the region in non-related industries, but amongst the other industry players across its northern neighbouring border.`,
      //   img: dapl,
      //   id: "dapl",
      // },
      {
        title: `Ping An`,
        description: `While many companies’ insufficient social policies are being exposed by the pandemic, those with strong policies in place are thriving, in contrast to their peers. Ping An Insurance was one that stood out from our early Pandemic monitoring.`,
        img: pingAn,
        id: "pingAn",
      },
      {
        title: "The Social Factor Theory of Change, June 2020",
        description: `What we know as veterans in the raw data collection and
        social impact qualification, quantification, measurement and
        management space is that within that entire SRI / ESG market,
        the Social Factors™ are not effectively represented and
        continue to be wildly misunderstood. This results in an ESG
        market that predominantly reports on E with a little bit of G,
        and a giant “?” for S. Learn more about S-Factor’s "Theory of Change” and a culmination of our work since 2009.`,
        img: theoryOfChange,
        id: "theoryOfChange",
      },
      {
        title: `COVID Case`,
        description: `In our recent report released in March of 2020, we captured a 35-day period of this performance from February 13 through to March 18, 2020, for 45 specific companies across 3 regions, to demonstrate the case. We found that the tone of sentiment versus the quantifiable action by companies in a timely manner as it related to their inherent fiduciary care where they operate, clearly defined the outperformers in a predictable fashion.`,
        img: covidSocial,
        id: "covid",
      },
      {
        title: `SRI 2013`,
        description: `The most recent print version of the original MSPI report is the 2014 publication, prior to converting to our now enhanced digital version via The S Factor Co.`,
        img: sri2013,
        id: "sri2013",
      },
      {
        title: `SRI 2012`,
        description: `The earliest MSPI reports begin recording performance in 2010, here is a sampling from our 2012 report covering 2011 FY for TSX.v Mining Companies. `,
        img: sri2012,
        id: "sri2012",
      },
    ],
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  handleReportClick(report) {
    this.setState({ modalIsOpen: true, activeReport: report })
  }

  handleFormSubmit(report) {
    this.setState({ errors: [] })

    const { email, name, company, position } = this.state

    const errors = []
    if (!email) {
      errors.push("email")
    } else if (!this.validateEmail(email)) {
      errors.push("email")
    }

    if (!name) {
      errors.push("name")
    }
    if (!company) {
      errors.push("company")
    }
    if (!position) {
      errors.push("position")
    }
    if (errors.length) {
      this.setState({ errors })
      return
    }

    const url = `https://us-central1-sfactor-website.cloudfunctions.net/sendReport?reportId=${report.id}&email=${email}&name=${name}&company=${company}&position=${position}`
    fetch(url)
      .then(() => {
        alert(
          `Please check your email for instructions on how to download ${report.title}.`
        )
        this.closeModal()
      })
      .catch(() => {
        alert("A problem occurred. Please double check the fields.")
      })
  }

  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false, activeReport: null })
  }

  renderReports() {
    return this.state.reports.map((report, i) => {
      return (
        <Section
          lessPadding={true}
          key={i}
          hasDivider={i < this.state.reports.length - 1}
        >
          <Group>
            <Col size="quarter">
              <a
                href="#"
                onClick={event => {
                  event.preventDefault()
                  this.handleReportClick(report)
                }}
              >
                <img src={report.img} width={120} height={120} />
              </a>
              <div style={{ height: 20 }} />
            </Col>
            <Col size="three-quarters">
              <div className="headingAccent" />

              <h4>{report.title}</h4>
              {/* <p>{report.description}</p> */}
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                children={report.description}
              />
              <CtaButton
                dark={true}
                onClick={() => this.handleReportClick(report)}
              >
                Download
              </CtaButton>
              <p></p>
            </Col>
          </Group>
        </Section>
      )
    })
  }

  renderModalContent() {
    if (!this.state.activeReport) {
      return
    }

    const report = this.state.activeReport

    return (
      <>
        <Group>
          <Col size="whole">
            <h3>Download {report.title}</h3>
            <div className="headingAccent"></div>
            <p>{report.description}</p>
            <hr />
            <p>Please provide the following info to download {report.title}.</p>
            <p></p>
          </Col>
        </Group>
        <Group>
          <Col size="half">
            <div className="form-group">
              <label>Name</label>
              <input
                tabIndex={1}
                type="text"
                className={`form-control ${
                  this.state.errors.includes("name") ? "error" : ""
                }`}
                placeholder="John Doe"
                value={this.state.name}
                onChange={evt =>
                  this.setState({ name: evt.target.value, errors: [] })
                }
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                tabIndex={3}
                className={`form-control ${
                  this.state.errors.includes("email") ? "error" : ""
                }`}
                placeholder="john@domain.com"
                value={this.state.email}
                onChange={evt =>
                  this.setState({ email: evt.target.value, errors: [] })
                }
              />
            </div>
          </Col>
          <Col size="half">
            <div className="form-group">
              <label>Company</label>
              <input
                tabIndex={2}
                type="text"
                className={`form-control ${
                  this.state.errors.includes("company") ? "error" : ""
                }`}
                placeholder="My Company Inc."
                value={this.state.company}
                onChange={evt =>
                  this.setState({ company: evt.target.value, errors: [] })
                }
              />
            </div>

            <div className="form-group">
              <label>Job Title</label>
              <input
                tabIndex={4}
                type="text"
                className={`form-control ${
                  this.state.errors.includes("position") ? "error" : ""
                }`}
                placeholder="Vice President"
                value={this.state.position}
                onChange={evt =>
                  this.setState({ position: evt.target.value, errors: [] })
                }
              />
            </div>
          </Col>
        </Group>
        <Group>
          <Col size="whole">
            <div style={{ textAlign: "right", paddingRight: 16 }}>
              <CtaButton
                dark={true}
                onClick={() => this.handleFormSubmit(report)}
              >
                Submit
              </CtaButton>
            </div>
          </Col>
        </Group>
      </>
    )
  }

  render() {
    return (
      <>
        <Layout>
          <SEO
            title="ESG Analysis | Blending Alternative Data"
            keywords={["ESG Analysis", "Blending Alternative Data"]}
            description="The S-Factor is featured for their contributions for better ESG analaysis and for a blend of alternative data sets for ESG. Contact us today! "
          />
          <TopContainer
            titleLines={["Analytics"]}
            backgroundImage={img}
            isVideo={true}
            mp4={
              "https://firebasestorage.googleapis.com/v0/b/sfactor-website.appspot.com/o/analytics3.mp4?alt=media"
            }
          />

          <Section>
            <Group>
              <Col size="half">
                <p>
                  Our data architecture is strategy agnostic, by design. Whether
                  our clients are seeking to mitigate their risk, comply with
                  regulations, benchmark best-practice, develop momentum and
                  longer-term strategies, or are seeking Alpha the platform
                  dashboard, custom reporting and API are versatile to suit
                  client’s needs. Here are some examples of S-Factor's data
                  applied.
                </p>
              </Col>
              <Col size="third">
                <div className="checks">
                  <Group>
                    <Col size="quarter">
                      <img src={iconCheck} height="32" />
                    </Col>
                    <Col size="three-quarters">
                      <p>Dynamic by structure </p>
                    </Col>
                  </Group>

                  <Group>
                    <Col size="quarter">
                      <img src={iconCheck} height="32" />
                    </Col>
                    <Col size="three-quarters">
                      <p>Agnostic by strategy</p>
                    </Col>
                  </Group>

                  <Group>
                    <Col size="quarter">
                      <img src={iconCheck} height="32" />
                    </Col>
                    <Col size="three-quarters">
                      <p>Versatile for customization</p>
                    </Col>
                  </Group>
                </div>
              </Col>
            </Group>
          </Section>
          {this.renderReports()}
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal.bind(this)}
            style={modalStyle}
            contentLabel="Example Modal"
            closeTimeoutMS={500}
          >
            <div className="modalContent">
              <div className="modalClose" onClick={this.closeModal.bind(this)}>
                Close
              </div>
              {this.renderModalContent()}
            </div>
          </Modal>
        </Layout>
      </>
    )
  }
}

const modalStyle = {
  overlay: {
    zIndex: 500,
    maxWidth: 620,
    width: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    marginLeft: -6,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "100%",
    // marginRight: '-50%',
    transform: "translate(-50%, -50%)",
    overflowY: "auto",
    maxHeight: "100vh",
  },
}

export default AnalyticsPage
